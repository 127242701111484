import { useMutation } from 'react-query'

import { activateUserSurvey } from '@/apis/survey/allocateUsers'

export default function useActivateUserSurvey() {
  return useMutation<void, unknown, { surveyId: number; userId: number }>(
    async ({ surveyId, userId }) => {
      await activateUserSurvey(surveyId, userId)
    }
  )
}