import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

const { SURVEY } = ROUTER

export type CreateSurveyRequestType = {
  name: string;
  description: string;
}

export const createSurvey = async(data: CreateSurveyRequestType) => {
  return request<void>({
    url: `${SURVEY}`,
    method: Methods.POST,
    data
  })
}