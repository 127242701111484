import { useMutation } from 'react-query'

import { deactivateUserSurvey } from '@/apis/survey/allocateUsers'

export default function useDeactivateUserSurvey() {
  return useMutation<void, unknown, { surveyId: number; userId: number }>(
    async ({ surveyId, userId }) => {
      await deactivateUserSurvey(surveyId, userId)
    }
  )
}