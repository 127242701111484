import { supabaseClient } from '@/libs/supabase'

export interface UserActivityLog {
  id: number;
  created_at: string;
  method: string;
  action: string;
  ip_address: string;
  fcm_token: string;
  device_info: string;
  user_id: string;
}

export interface DailyStats {
  date: string;
  unique_users: number;
  total_activities: number;
}

export const getUserActivityLogs = async (): Promise<UserActivityLog[]> => {
  const { data, error } = await supabaseClient
    .from('user_activity_logs')
    .select('*')
    .order('created_at', { ascending: false })
    .limit(10000)

  if (error) {
    throw error
  }

  return data || []
}

export const getDailyStats = async (days = 30): Promise<DailyStats[]> => {
  const endDate = new Date()
  const startDate = new Date(endDate)
  startDate.setDate(startDate.getDate() - days)

  const { data, error } = await supabaseClient
    .from('user_activity_logs')
    .select('created_at, ip_address, user_id')
    .gte('created_at', startDate.toISOString())
    .lte('created_at', endDate.toISOString())
    .order('created_at', { ascending: false })
    .limit(10000)

  if (error) {
    throw error
  }

  // 모든 날짜에 대한 초기 통계 생성
  const dailyStats = new Map<string, { unique_users: Set<string>; total_activities: number }>()

  // 시작일부터 종료일까지 모든 날짜 초기화
  for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
    const dateStr = new Date(d).toISOString().split('T')[0]
    dailyStats.set(dateStr, { unique_users: new Set(), total_activities: 0 })
  }

  // 로그 데이터로 통계 업데이트
  data?.forEach((log) => {
    const date = new Date(log.created_at)
    date.setHours(date.getHours() + 9)
    const dateStr = date.toISOString().split('T')[0]
    const stats = dailyStats.get(dateStr)
    if (stats) {
      stats.unique_users.add(`${log.ip_address}-${log.user_id}`)
      stats.total_activities++
    }
  })

  return Array.from(dailyStats.entries())
    .map(([date, stats]) => ({
      date,
      unique_users: stats.unique_users.size,
      total_activities: stats.total_activities
    }))
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
}
