import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

import { SurveyModel } from './getSurvey'

const { SURVEY } = ROUTER

export type GetSurveysResponseType = SurveyModel[]

export const getSurveys = () => {
  return request<GetSurveysResponseType>({
    url: `${SURVEY}`,
    method: Methods.GET
  })
}