import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

import { QuestionType } from './types'

const { SURVEY_QUESTIONS } = ROUTER

export type CreateQuestionRequestType = {
  question: string;
  type: QuestionType;
  required?: boolean;
  order?: number;
  surveyId: number;
  options?: Array<{
    content: string;
    order?: number;
    isOther?: boolean;
  }>;
}

export const createQuestion = async(data: CreateQuestionRequestType) => {
  const { options, ...questionData } = data
  return request<void>({
    url: `${SURVEY_QUESTIONS}`,
    method: Methods.POST,
    data: {
      ...questionData,
      options: options?.map((option, index) => ({
        ...option,
        order: option.order ?? index + 1
      }))
    }
  })
}