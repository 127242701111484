import { useMutation } from 'react-query'

import { allocateUsers, AllocateUsersRequestType } from '@/apis/survey/allocateUsers'

export default function useAllocateUsers() {
  return useMutation<void, unknown, { surveyId: number; data: AllocateUsersRequestType }>(
    async ({ surveyId, data }) => {
      await allocateUsers(surveyId, data)
    }
  )
}