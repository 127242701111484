import { Methods, request } from '@/libs/request'

export type PostCreatePostFilterType = {
  code: string;
  title: string;
  subTitle: string;
  word: string;
  image: string;
  show: boolean;
  priority: number;
}

export const postCreatePostFilter = async(data: PostCreatePostFilterType) => {
  return request<{ id: number }>({
    url: '/admin-post/filter',
    method: Methods.POST,
    data
  })
}
