import {
  BrowserRouter,
  Route,
  Routes
} from 'react-router-dom'

import FestivalScheduleCreatePage from '@/pages/festival/FestivalScheduleCreatePage'
import FestivalSchedulePage from '@/pages/festival/FestivalSchedulePage'
import FestivalScheduleRevisePage from '@/pages/festival/FestivalScheduleRevisePage'

import FestivalCreatePage from './pages/festival/FestivalCreatePage'
import FestivalDetailPage from './pages/festival/FestivalDetailPage'
import FestivalFacilityCreatePage from './pages/festival/FestivalFacilityCreatePage'
import FestivalPage from './pages/festival/FestivalPage'
import FestivalParticipatorCreatePage from './pages/festival/FestivalParticipatorCreatePage'
import FestivalRevisePage from './pages/festival/FestivalRevisePage'
import ActivityLogPage from './pages/log/ActivityLogPage'
import MainPage from './pages/main/MainPage'
import NotificationMainPage from './pages/notification/NotificationMainPage'
import PostFilterCreatePage from './pages/post/PostFilterCreatePage'
import PostFilterPage from './pages/post/PostFilterPage'
import PostMainPage from './pages/post/PostMainPage'
import BrandCreatePage from './pages/shopping/BrandCreatePage'
import BrandMainPage from './pages/shopping/BrandMainPage'
import ProductCreatePage from './pages/shopping/ProductCreatePage'
import ProductDetailPage from './pages/shopping/ProductDetailPage'
import ProductMainPage from './pages/shopping/ProductMainPage'
import ShopCreatePage from './pages/shopping/ShopCreatePage'
import ShopDetailPage from './pages/shopping/ShopDetailPage'
import ShoppingFilterCreatePage from './pages/shopping/ShoppingFilterCreatePage'
import ShoppingFilterPage from './pages/shopping/ShoppingFilterPage'
import ShoppingMainPage from './pages/shopping/ShoppingMainPage'
import ShopRevisePage from './pages/shopping/ShopRevisePage'
import SurveyDetailPage from './pages/survey/SurveyDetailPage'
import SurveyMainPage from './pages/survey/SurveyMainPage'
import SurveyStatisticsPage from './pages/survey/SurveyStatisticsPage'
import SurveyUserAllocatePage from './pages/survey/SurveyUserAllocatePage'
import TeaCreatePage from './pages/tea/TeaCreatePage'
import TeaDetailPage from './pages/tea/TeaDetailPage'
import TeaMainPage from './pages/tea/TeaMainPage'
import TeaNationCreatePage from './pages/tea/TeaNationCreatePage'
import TeaNationMainPage from './pages/tea/TeaNationMainPage'
import TeaNationUpdatePage from './pages/tea/TeaNationUpdatePage'
import TeaOriginCreatePage from './pages/tea/TeaOriginCreatePage'
import TeaOriginMainPage from './pages/tea/TeaOriginMainPage'
import TeaOriginUpdatePage from './pages/tea/TeaOriginUpdatePage'
import TeaUpdatePage from './pages/tea/TeaUpdatePage'
import TeaTogetherAdminPage from './pages/tea-together/TeaTogetherAdminPage'
import TeahouseAdminPage from './pages/teahouse/TeahouseAdminPage'
import TeahouseFilterCreatePage from './pages/teahouse/TeahouseFilterCreatePage'
import TeahouseFilterPage from './pages/teahouse/TeahouseFilterPage'
import UserPage from './pages/user/UserPage'
import { ROUTER } from './router'

const { MAIN, TEAHOUSE, TEATOGETHER, POST, NOTIFICATION, FESTIVAL, SHOPPING, TEA, ACTIVITY_LOG, SURVEY } = ROUTER
function PageRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/user'>
          <Route
            index
            element={<UserPage />}
          />
        </Route>
        <Route path={MAIN}>
          <Route
            index
            element={<MainPage />}
          />
        </Route>
        <Route path={ACTIVITY_LOG}>
          <Route
            index
            element={<ActivityLogPage />}
          />
        </Route>
        <Route path={TEAHOUSE}>
          <Route
            index
            element={<TeahouseAdminPage />}
          />
          <Route path='filter'>
            <Route
              index
              element={<TeahouseFilterPage />}
            />
            <Route path='create'>
              <Route
                index
                element={<TeahouseFilterCreatePage />}
              />
            </Route>
          </Route>
        </Route>
        <Route path={TEATOGETHER}>
          <Route
            index
            element={<TeaTogetherAdminPage />}
          />
        </Route>
        <Route path={POST}>
          <Route
            index
            element={<PostMainPage />}
          />
          <Route path='filter'>
            <Route
              index
              element={<PostFilterPage />}
            />
            <Route path='create'>
              <Route
                index
                element={<PostFilterCreatePage />}
              />
            </Route>
          </Route>
        </Route>
        <Route path={NOTIFICATION}>
          <Route
            index
            element={<NotificationMainPage />}
          />
        </Route>
        <Route path={SHOPPING}>
          <Route
            index
            element={<ShoppingMainPage />}
          />
          <Route
            path='product'
            element={<ProductMainPage />}
          />
          <Route
            path='product/:id'
            element={<ProductDetailPage />}
          />
          <Route
            path='shop/create'
            element={<ShopCreatePage />}
          />
          <Route
            path='shop/:id'
            element={<ShopDetailPage />}
          />
          <Route
            path='shop/:id/edit'
            element={<ShopRevisePage />}
          />
          <Route
            path='shop/:id/product/create'
            element={<ProductCreatePage />}
          />
          <Route
            path='brand/create'
            element={<BrandCreatePage />}
          />
          <Route
            path='brand'
            element={<BrandMainPage />}
          />
        </Route>
        <Route path={FESTIVAL}>
          <Route
            index
            element={<FestivalPage />}
          />
          <Route
            path='create'
            element={<FestivalCreatePage />}>
          </Route>
          <Route
            path=':id'
            element={<FestivalDetailPage />}>
          </Route>
          <Route
            path=':id/revise'
            element={<FestivalRevisePage />}>
          </Route>
          <Route
            path=':id/facility/create'
            element={<FestivalFacilityCreatePage />}>
          </Route>
          <Route
            path=':id/participator/create'
            element={<FestivalParticipatorCreatePage />}>
          </Route>
        </Route>
        <Route path={TEA}>
          <Route
            index
            element={<TeaMainPage />} />
          <Route
            path="tea/create"
            element={<TeaCreatePage />} />
          <Route
            path="tea/:id"
            element={<TeaDetailPage />} />
          <Route
            path="tea/update/:id"
            element={<TeaUpdatePage />} />
          <Route path="nation">
            <Route
              index
              element={<TeaNationMainPage />} />
            <Route
              path="create"
              element={<TeaNationCreatePage />} />
            <Route
              path="update/:id"
              element={<TeaNationUpdatePage />} />
          </Route>
          <Route path="origin">
            <Route
              index
              element={<TeaOriginMainPage />} />
            <Route
              path="create"
              element={<TeaOriginCreatePage />} />
            <Route
              path="update/:id"
              element={<TeaOriginUpdatePage />} />
          </Route>
        </Route>
        <Route path='/festival/schedule'>
          <Route
            index
            element={<FestivalSchedulePage />}
          />
        </Route>
        <Route path='/festival/schedule/create'>
          <Route
            index
            element={<FestivalScheduleCreatePage />}
          />
        </Route>
        <Route path='/festival/schedule/revise/:id'>
          <Route
            index
            element={<FestivalScheduleRevisePage />}
          />
        </Route>
        <Route path='/shopping/filter'>
          <Route
            index
            element={<ShoppingFilterPage />}
          />
          <Route
            path='create'
            element={<ShoppingFilterCreatePage />}
          />
        </Route>
        <Route path={SURVEY}>
          <Route
            index
            element={<SurveyMainPage />}
          />
          <Route
            path=':id'
            element={<SurveyDetailPage />}
          />
          <Route
            path=':id/users'
            element={<SurveyUserAllocatePage />}
          />
          <Route
            path=':id/statistics'
            element={<SurveyStatisticsPage />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
export default PageRouter
