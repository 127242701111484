import { useQuery } from 'react-query'

import { getSurveyDetail, SurveyDetailModel } from '@/apis/survey/getSurveyDetail'

const getQueryKey = (id: string) => [`survey/${id}`]

export default function useGetSurveyDetail(id: string) {
  return useQuery<SurveyDetailModel>(
    getQueryKey(id),
    async () => {
      const survey = await getSurveyDetail(Number(id))
      console.log('Survey Detail Response:', survey)
      return survey
    },
    { enabled: !!id }
  )
}