import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

const { SURVEY_USER_NEED_TO_RESPOND } = ROUTER

export type AllocateUsersRequestType = {
  userIds: number[];
  target?: 'ALL' | 'TARGET';
}

export const allocateUsers = async (surveyId: number, data: AllocateUsersRequestType) => {
  return request<void>({
    url: `${SURVEY_USER_NEED_TO_RESPOND}/${surveyId}/allocate`,
    method: Methods.POST,
    data
  })
}

export const deactivateUserSurvey = async (surveyId: number, userId: number) => {
  return request<void>({
    url: `${SURVEY_USER_NEED_TO_RESPOND}/${surveyId}/users/${userId}`,
    method: Methods.DELETE
  })
}

export const activateUserSurvey = async (surveyId: number, userId: number) => {
  const url = `${SURVEY_USER_NEED_TO_RESPOND}/${surveyId}/users/${userId}/activate`
  console.log('Activate URL:', url)
  return request<void>({
    url,
    method: Methods.POST
  })
}