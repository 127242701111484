import { useMutation } from 'react-query'

import { updateSurveyStatus, UpdateSurveyStatusRequestType } from '@/apis/survey/updateSurveyStatus'

export default function useUpdateSurveyStatus() {
  return useMutation<void, unknown, { id: number; data: UpdateSurveyStatusRequestType }>(
    async ({ id, data }) => {
      await updateSurveyStatus(id, data)
    }
  )
}