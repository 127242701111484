import { useMutation } from 'react-query'

import { updateQuestionOrder, UpdateQuestionOrderRequestType } from '@/apis/survey/updateQuestionOrder'

export default function useUpdateQuestionOrder() {
  return useMutation<void, unknown, { id: number; data: UpdateQuestionOrderRequestType }>(
    async ({ id, data }) => {
      await updateQuestionOrder(id, data)
    }
  )
}