import { useQuery } from 'react-query'

import { getSurveys, GetSurveysResponseType } from '@/apis/survey/getSurveys'

const getQueryKey = () => ['surveys']

export default function useGetSurveys() {
  return useQuery<GetSurveysResponseType>(
    getQueryKey(),
    async () => {
      const surveys = await getSurveys()
      return surveys
    }
  )
}