import { Methods, request } from '@/libs/request'

export type PostCreateFilteredPostType = {
  filterId: number;
  postId: number;
}

export const postCreateFilteredPost = async({ postId, filterId }: PostCreateFilteredPostType) => {
  return request<{ id: number }>({
    url: `/admin-post/filter/${filterId}/post/${postId}`,
    method: Methods.POST
  })
}
