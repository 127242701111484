import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

const { SURVEY } = ROUTER

export type UpdateSurveyStatusRequestType = {
  isActive: boolean;
}

export const updateSurveyStatus = async(id: number, data: UpdateSurveyStatusRequestType) => {
  return request<void>({
    url: `${SURVEY}/${id}`,
    method: Methods.PATCH,
    data
  })
}