import { Methods, request } from '@/libs/request'

export interface SurveyStatistics {
  id: number;
  name: string;
  users: {
    id: number;
    name: string;
  }[];
  questions: {
    id: number;
    question: string;
    options: {
      id: number;
      content: string;
      count: number;
    }[];
    answers: {
      id: number;
      user: {
        id: number;
        name: string;
      };
      answer: string;
      optionId: number | null;
    }[];
  }[];
  totalResponses: number;
}

export const getSurveyStatistics = (surveyId: number) => {
  return request<SurveyStatistics>({
    method: Methods.GET,
    url: `/survey/${surveyId}/statistics`
  })
}