import { useQuery } from 'react-query'

import { getSurveyUsers, SurveyUserModel } from '@/apis/survey/getSurveyUsers'

export default function useGetSurveyUsers(surveyId: string) {
  return useQuery<SurveyUserModel[]>(
    ['survey', surveyId, 'users'],
    async () => {
      const users = await getSurveyUsers(Number(surveyId))
      return users
    },
    { enabled: !!surveyId }
  )
}