import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

const { SURVEY_QUESTIONS } = ROUTER

export type UpdateQuestionOrderRequestType = {
  order: number;
}

export const updateQuestionOrder = async(id: number, data: UpdateQuestionOrderRequestType) => {
  return request<void>({
    url: `${SURVEY_QUESTIONS}/${id}/order`,
    method: Methods.PATCH,
    data
  })
}