import { useQuery } from '@tanstack/react-query'

import { getSurveyStatistics, SurveyStatistics } from '@/apis/survey/getSurveyStatistics'

export const useSurveyStatistics = (surveyId: number) => {
  return useQuery<SurveyStatistics>({
    queryKey: ['survey', surveyId, 'statistics'],
    queryFn: () => getSurveyStatistics(surveyId),
    enabled: !!surveyId
  })
}