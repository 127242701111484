import { useMutation } from 'react-query'

import { createSurvey, CreateSurveyRequestType } from '@/apis/survey/createSurvey'

export default function useCreateSurvey() {
  return useMutation<void, unknown, CreateSurveyRequestType>(
    async (data) => {
      await createSurvey(data)
    }
  )
}