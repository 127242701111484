import { ThemeProvider } from '@emotion/react'
import { createTheme } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import ErrorBoundary from './ErrorBoundary'
import PageRouter from './PageRouter'
import Error from './pages/Error'

const theme = createTheme()
const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary fallback={<Error />}>
        <ThemeProvider theme={theme}>
          <PageRouter />
        </ThemeProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  )
}

export default App
