import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

import { QuestionType } from './types'

const { SURVEY } = ROUTER

export type SurveyDetailModel = {
  id: number;
  name: string;
  description: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  questions: SurveyQuestionModel[];
}

export type SurveyQuestionModel = {
  id: number;
  question: string;
  type: QuestionType;
  required: boolean;
  order: number;
  options: SurveyQuestionOptionModel[];
}

export type SurveyQuestionOptionModel = {
  id: number;
  content: string;
  order: number;
  isOther: boolean;
}

export const getSurveyDetail = (id: number) => {
  return request<SurveyDetailModel>({
    url: `${SURVEY}/${id}`,
    method: Methods.GET
  })
}