import styled from '@emotion/styled'
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { format } from 'date-fns'
import { useState } from 'react'
import { Line } from 'react-chartjs-2'
import { useQuery } from 'react-query'

import { type UserActivityLog, getDailyStats, getUserActivityLogs } from '@/apis/log/getUserActivityLogs'

const DAU_GOAL = 50

// ChartJS 등록
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
)

const ActivityLogPage = () => {
  const [selectedDate, setSelectedDate] = useState<string>(new Date().toISOString().split('T')[0])
  const [activeTab, setActiveTab] = useState<'activities' | 'users'>('activities')

  const { data: logs = [] } = useQuery({
    queryKey: ['activity-logs'],
    queryFn: getUserActivityLogs
  })

  const { data: dailyStats = [] } = useQuery({
    queryKey: ['daily-stats'],
    queryFn: () => getDailyStats(30)
  })

  // Group logs by ip_address and user_id combination and filter by selected date
  const groupedLogs = logs.reduce<Record<string, UserActivityLog[]>>((acc, log) => {
    const logDate = new Date(log.created_at).toISOString().split('T')[0]
    if (logDate === selectedDate) {
      const key = `${log.ip_address}-${log.user_id}`
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(log)
    }
    return acc
  }, {})

  // 차트 데이터 준비
  const sortedStats = [...dailyStats].sort((a, b) => {
    const dateA = new Date(new Date(a.date).getTime() + 9 * 60 * 60 * 1000)
    const dateB = new Date(new Date(b.date).getTime() + 9 * 60 * 60 * 1000)
    return dateA.getTime() - dateB.getTime()
  })

  const activityChartData = {
    labels: sortedStats.map((stat) => {
      const date = new Date(new Date(stat.date).getTime() + 9 * 60 * 60 * 1000)
      return format(date, 'M/d')
    }),
    datasets: [
      {
        label: '활동 수',
        data: sortedStats.map((stat) => stat.total_activities),
        borderColor: '#007bff',
        backgroundColor: 'rgba(0, 123, 255, 0.1)',
        fill: true,
        tension: 0.4
      }
    ]
  }

  const userChartData = {
    labels: sortedStats.map((stat) => {
      const date = new Date(new Date(stat.date).getTime() + 9 * 60 * 60 * 1000)
      return format(date, 'M/d')
    }),
    datasets: [
      {
        label: '사용자 수',
        data: sortedStats.map((stat) => stat.unique_users),
        borderColor: '#28a745',
        backgroundColor: 'rgba(40, 167, 69, 0.1)',
        fill: true,
        tension: 0.4
      }
    ]
  }

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { position: 'top' as const },
      title: {
        display: true,
        text: activeTab === 'activities' ? '최근 30일 활동 통계' : '최근 30일 사용자 통계',
        font: { size: 16 }
      },
      tooltip: { enabled: true, mode: 'index' as const, intersect: false },
      datalabels: {
        display: true,
        color: '#333',
        align: 'top' as const,
        offset: 6,
        font: { size: 11, weight: 500 },
        formatter: (value: number) => value
      }
    },
    scales: { y: { beginAtZero: true } },
    elements: { point: { radius: 4, hoverRadius: 6 } }
  }

  // StatsContainer는 최신순으로 정렬
  const sortedDailyStats = [...dailyStats].sort((a, b) => {
    const dateA = new Date(new Date(a.date).getTime() + 9 * 60 * 60 * 1000)
    const dateB = new Date(new Date(b.date).getTime() + 9 * 60 * 60 * 1000)
    return dateB.getTime() - dateA.getTime()
  })

  return (
    <Container>
      <Header>
        <h1>Activity Logs</h1>
        <TabContainer>
          <Tab
            isActive={activeTab === 'activities'}
            onClick={() => setActiveTab('activities')}
          >
            활동 통계
          </Tab>
          <Tab
            isActive={activeTab === 'users'}
            onClick={() => setActiveTab('users')}
          >
            사용자 통계
          </Tab>
        </TabContainer>
        <ChartContainer>
          <Line
            data={activeTab === 'activities' ? activityChartData : userChartData}
            options={chartOptions}
          />
        </ChartContainer>
        <StatsContainer>
          <h2>Daily Statistics (Last 30 Days)</h2>
          <StatsGrid>
            {sortedDailyStats.map((stat) => (
              <StatCard
                key={stat.date}
                onClick={() => setSelectedDate(stat.date)}
                isSelected={selectedDate === stat.date}
              >
                <StatDate>{format(new Date(stat.date), 'MMM dd')}</StatDate>
                <StatValue>
                  <strong>{stat.unique_users}</strong>
                  <span>/ {DAU_GOAL} Users</span>
                </StatValue>
                <ProgressBar>
                  <Progress width={Math.min((stat.unique_users / DAU_GOAL) * 100, 100)} />
                </ProgressBar>
                <StatDetail>
                  <div>Total Activities: {stat.total_activities}</div>
                  <div>Avg. Activities per User: {(stat.total_activities / stat.unique_users).toFixed(1)}</div>
                </StatDetail>
              </StatCard>
            ))}
          </StatsGrid>
        </StatsContainer>
      </Header>

      <ActivitySection>
        <h2>User Activities - {format(new Date(selectedDate), 'yyyy년 MM월 dd일')}</h2>
        {Object.entries(groupedLogs).length === 0 ? (
          <EmptyState>선택한 날짜에 활동 기록이 없습니다.</EmptyState>
        ) : (
          Object.entries(groupedLogs).map(([key, userLogs]) => (
            <UserTimelineSection key={key}>
              <UserInfo>
                <h3>User Information</h3>
                <p>IP: {userLogs[0].ip_address}</p>
                <p>User ID: {userLogs[0].user_id}</p>
                <p>Device: {userLogs[0].device_info}</p>
              </UserInfo>
              <Timeline>
                {[...userLogs].sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()).map((log) => (
                  <TimelineItem key={log.id}>
                    <TimelineContent>
                      <Time>{format(new Date(log.created_at), 'HH:mm:ss')}</Time>
                      <Activity>
                        <Method>{log.method}</Method>
                        <Action>{log.action}</Action>
                      </Activity>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </UserTimelineSection>
          ))
        )}
      </ActivitySection>
    </Container>
  )
}

const Container = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`

const Header = styled.div`
  margin-bottom: 3rem;

  h1 {
    margin-bottom: 2rem;
  }
`

const TabContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`

interface TabProps {
  isActive: boolean;
}

const Tab = styled.button<TabProps>`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background: ${(props) => props.isActive ? '#007bff' : '#f8f9fa'};
  color: ${(props) => props.isActive ? 'white' : '#333'};
  font-weight: ${(props) => props.isActive ? '600' : '400'};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${(props) => props.isActive ? '#0056b3' : '#e9ecef'};
  }
`

const ChartContainer = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  height: 300px;
`

const StatsContainer = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    margin-bottom: 1.5rem;
    font-size: 1.25rem;
  }
`

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
`

interface StatCardProps {
  isSelected: boolean;
}

const StatCard = styled.div<StatCardProps>`
  background: ${(props) => props.isSelected ? '#e3f2fd' : '#f8f9fa'};
  padding: 1rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 2px solid ${(props) => props.isSelected ? '#007bff' : 'transparent'};

  &:hover {
    background: ${(props) => props.isSelected ? '#e3f2fd' : '#f1f3f5'};
    transform: translateY(-2px);
  }
`

const StatDate = styled.div`
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`

const StatValue = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  strong {
    font-size: 1.5rem;
  }

  span {
    color: #666;
  }
`

const ProgressBar = styled.div`
  height: 6px;
  background: #e9ecef;
  border-radius: 3px;
  margin-bottom: 1rem;
`

interface ProgressProps {
  width: number;
}

const Progress = styled.div<ProgressProps>`
  height: 100%;
  width: ${(props) => props.width}%;
  background: ${(props) => props.width >= 100 ? '#28a745' : '#007bff'};
  border-radius: 3px;
  transition: width 0.3s ease;
`

const StatDetail = styled.div`
  font-size: 0.9rem;
  color: #666;

  div {
    margin-bottom: 0.25rem;
  }
`

const ActivitySection = styled.div`
  h2 {
    margin-bottom: 1.5rem;
  }
`

const EmptyState = styled.div`
  text-align: center;
  padding: 3rem;
  background: white;
  border-radius: 8px;
  color: #666;
  font-size: 1.1rem;
`

const UserTimelineSection = styled.section`
  margin-bottom: 3rem;
  padding: 1.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: white;
`

const UserInfo = styled.div`
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e0e0e0;

  h3 {
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0.25rem 0;
    color: #666;
  }
`

const Timeline = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem 0;
`

const TimelineItem = styled.div`
  position: relative;
  padding: 0 1.5rem;
  min-width: 150px;
  max-width: 180px;
  
  &:not(:last-child)::after {
    content: '→';
    position: absolute;
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    font-size: 1.2rem;
  }

  // 줄바꿈 시 마지막 아이템이 아닌 경우에만 화살표 표시
  @media (max-width: 768px) {
    &:nth-child(3n)::after {
      display: none;
    }
  }
`

const TimelineContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`

const Time = styled.span`
  color: #666;
  font-size: 0.85rem;
  margin-bottom: 0.3rem;
`

const Activity = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const Method = styled.span`
  padding: 0.2rem 0.4rem;
  background-color: #e3f2fd;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 500;
`

const Action = styled.span`
  color: #333;
`

export default ActivityLogPage
