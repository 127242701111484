import { Button, Card, Form, Input, message, Modal, Popconfirm, Radio, Table, Tag } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import useActivateUserSurvey from '@/hooks/survey/useActivateUserSurvey'
import useAllocateUsers from '@/hooks/survey/useAllocateUsers'
import useDeactivateUserSurvey from '@/hooks/survey/useDeactivateUserSurvey'
import useGetSurveyDetail from '@/hooks/survey/useGetSurveyDetail'
import useGetSurveyUsers from '@/hooks/survey/useGetSurveyUsers'

interface AllocateUserFormValues {
  userIds: string;
  target: 'ALL' | 'TARGET';
}

export default function SurveyUserAllocatePage() {
  const { id = '' } = useParams()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [form] = Form.useForm<AllocateUserFormValues>()

  const { data: survey } = useGetSurveyDetail(id)
  const { data: surveyUsers = [], refetch: refetchSurveyUsers } = useGetSurveyUsers(id)
  const allocateUsersMutation = useAllocateUsers()
  const deactivateUserSurveyMutation = useDeactivateUserSurvey()
  const activateUserSurveyMutation = useActivateUserSurvey()

  const handleAllocateUsers = async (values: AllocateUserFormValues) => {
    try {
      const userIds = values.target === 'TARGET'
        ? values.userIds
          .split(',')
          .map((id) => Number(id.trim()))
          .filter((id) => !isNaN(id))
        : []

      if (values.target === 'TARGET' && userIds.length === 0) {
        message.error('할당할 사용자 ID를 입력해주세요')
        return
      }

      await allocateUsersMutation.mutateAsync({
        surveyId: Number(id),
        data: {
          userIds,
          target: values.target
        }
      })
      message.success('사용자 할당이 완료되었습니다')
      setIsModalOpen(false)
      form.resetFields()
      refetchSurveyUsers()
    } catch (error) {
      message.error('사용자 할당에 실패했습니다')
    }
  }

  const handleDeactivate = async (userId: number) => {
    try {
      await deactivateUserSurveyMutation.mutateAsync({
        surveyId: Number(id),
        userId
      })
      message.success('링크가 비활성화되었습니다')
      refetchSurveyUsers()
    } catch (error) {
      message.error('링크 비활성화에 실패했습니다')
    }
  }

  const handleActivate = async (userId: number) => {
    try {
      await activateUserSurveyMutation.mutateAsync({
        surveyId: Number(id),
        userId
      })
      message.success('링크가 활성화되었습니다')
      refetchSurveyUsers()
    } catch (error) {
      message.error('링크 활성화에 실패했습니다')
    }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: '사용자 ID',
      dataIndex: 'userId',
      key: 'userId'
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '상태',
      key: 'status',
      render: (record: { isCompleted: boolean; isActive: boolean }) => {
        if (record.isCompleted) {
          return <Tag color="success">완료</Tag>
        }
        if (!record.isActive) {
          return <Tag color="error">비활성화</Tag>
        }
        return <Tag color="processing">진행중</Tag>
      }
    },
    {
      title: '할당일',
      dataIndex: 'allocatedAt',
      key: 'allocatedAt',
      render: (date: string) => new Date(date).toLocaleDateString()
    },
    {
      title: '작업',
      key: 'action',
      render: (record: { id: number; userId: number; isActive: boolean; isCompleted: boolean }) => {
        if (record.isCompleted) return null

        return record.isActive ? (
          <Popconfirm
            title="링크 비활성화"
            description="정말 이 사용자의 설문 링크를 비활성화하시겠습니까?"
            onConfirm={() => handleDeactivate(record.userId)}
            okText="확인"
            cancelText="취소"
          >
            <Button
              type="link"
              danger>
              링크 비활성화
            </Button>
          </Popconfirm>
        ) : (
          <Popconfirm
            title="링크 활성화"
            description="이 사용자의 설문 링크를 다시 활성화하시겠습니까?"
            onConfirm={() => handleActivate(record.userId)}
            okText="확인"
            cancelText="취소"
          >
            <Button
              type="link">
              링크 활성화
            </Button>
          </Popconfirm>
        )
      }
    }
  ]

  if (!survey) return <div className="p-6">로딩 중...</div>

  return (
    <div className="p-6">
      <div className="flex justify-between mb-4">
        <div>
          <h1 className="text-2xl font-bold">{survey.name}</h1>
          {survey.description && (
            <p className="text-gray-600 mt-2">{survey.description}</p>
          )}
        </div>
        <Button
          type="primary"
          onClick={() => setIsModalOpen(true)}
        >
          사용자 할당
        </Button>
      </div>

      <Card title="할당된 사용자">
        <Table
          columns={columns}
          dataSource={surveyUsers}
          rowKey="id"
        />
      </Card>

      <Modal
        title="사용자 할당"
        open={isModalOpen}
        onOk={() => form.submit()}
        onCancel={() => {
          setIsModalOpen(false)
          form.resetFields()
        }}
        confirmLoading={allocateUsersMutation.isLoading}
      >
        <Form
          form={form}
          onFinish={handleAllocateUsers}
          layout="vertical"
        >
          <Form.Item
            name="target"
            label="할당 대상"
            rules={[{ required: true, message: '할당 대상을 선택해주세요' }]}
          >
            <Radio.Group>
              <Radio value="TARGET">특정 사용자</Radio>
              <Radio value="ALL">전체 사용자</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) => prev.target !== curr.target}
          >
            {({ getFieldValue }) => getFieldValue('target') === 'TARGET' && (
              <Form.Item
                name="userIds"
                label="사용자 ID"
                rules={[{ required: true, message: '사용자 ID를 입력해주세요' }]}
                extra="여러 사용자를 할당하려면 ID를 쉼표(,)로 구분하여 입력하세요"
              >
                <Input.TextArea
                  placeholder="예: 1, 2, 3"
                  rows={4}
                />
              </Form.Item>
            )
            }
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}