import { Methods, request } from '@/libs/request'
import { ROUTER } from '@/router'

import { QuestionType } from './types'

const { SURVEY_QUESTIONS } = ROUTER

export type UpdateQuestionRequestType = {
  question?: string;
  type?: QuestionType;
  required?: boolean;
  order?: number;
  options?: Array<{
    content: string;
    order?: number;
    isOther?: boolean;
  }>;
}

export const updateQuestion = async(id: number, data: UpdateQuestionRequestType) => {
  const { options, ...questionData } = data
  return request<void>({
    url: `${SURVEY_QUESTIONS}/${id}`,
    method: Methods.PATCH,
    data: {
      ...questionData,
      options: options?.map((option, index) => ({
        ...option,
        order: option.order ?? index + 1
      }))
    }
  })
}