import { Button, Card, Checkbox, Form, Input, message, Modal, Radio, Space } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useParams } from 'react-router-dom'

import { SurveyQuestionModel } from '@/apis/survey/getSurveyDetail'
import { QuestionType } from '@/apis/survey/types'
import useCreateQuestion from '@/hooks/survey/useCreateQuestion'
import useGetSurveyDetail from '@/hooks/survey/useGetSurveyDetail'
import useUpdateQuestion from '@/hooks/survey/useUpdateQuestion'
import useUpdateQuestionOrder from '@/hooks/survey/useUpdateQuestionOrder'

interface QuestionFormValues {
  question: string;
  type: QuestionType;
  required?: boolean;
  options?: Array<{
    content: string;
    isOther?: boolean;
  }>;
}

export default function SurveyDetailPage() {
  const { id = '' } = useParams()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editingQuestion, setEditingQuestion] = useState<SurveyQuestionModel | null>(null)
  const [form] = Form.useForm<QuestionFormValues>()

  const { data: survey, refetch } = useGetSurveyDetail(id)
  console.log('Survey in component:', survey)
  const createQuestionMutation = useCreateQuestion()
  const updateQuestionMutation = useUpdateQuestion()
  const updateQuestionOrderMutation = useUpdateQuestionOrder()

  const handleOpenModal = (question?: SurveyQuestionModel) => {
    if (question) {
      setEditingQuestion(question)
      form.setFieldsValue({
        question: question.question,
        type: question.type,
        required: question.required,
        options: question.options?.map((opt) => ({
          content: opt.content,
          isOther: opt.isOther
        }))
      })
    } else {
      setEditingQuestion(null)
      form.resetFields()
    }
    setIsModalOpen(true)
  }

  const handleSubmit = async (values: QuestionFormValues) => {
    if (values.type !== QuestionType.SUBJECTIVE && values.options) {
      const otherOptionsCount = values.options.filter((opt) => opt.isOther).length
      if (otherOptionsCount > 1) {
        message.error('기타 옵션은 하나만 추가할 수 있습니다.')
        return
      }
    }

    if (editingQuestion) {
      updateQuestionMutation.mutate(
        {
          id: editingQuestion.id,
          data: {
            ...values,
            options: values.options?.map((option, index) => ({
              ...option,
              order: index + 1
            }))
          }
        },
        {
          onSuccess: () => {
            setIsModalOpen(false)
            form.resetFields()
            setEditingQuestion(null)
            refetch()
          }
        }
      )
    } else {
      createQuestionMutation.mutate(
        {
          ...values,
          surveyId: Number(id),
          order: survey?.questions?.length ? survey.questions.length + 1 : 1,
          options: values.options?.map((option, index) => ({
            ...option,
            order: index + 1
          }))
        },
        {
          onSuccess: () => {
            setIsModalOpen(false)
            form.resetFields()
            refetch()
          }
        }
      )
    }
  }

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination || !survey) return

    const { source, destination } = result
    const questions = Array.from(survey.questions)
    const [reorderedQuestion] = questions.splice(source.index, 1)
    questions.splice(destination.index, 0, reorderedQuestion)

    // 순서 업데이트를 순차적으로 처리
    for (let index = 0; index < questions.length; index++) {
      const question = questions[index]
      try {
        await updateQuestionOrderMutation.mutateAsync({
          id: question.id,
          data: { order: index + 1 }
        })
      } catch (error) {
        console.error('Failed to update question order:', error)
      }
    }

    // 모든 순서 업데이트가 완료된 후 refetch
    refetch()
  }

  if (!survey) return <div className="p-6">로딩 중...</div>

  return (
    <div className="p-6">
      <div className="flex justify-between mb-4">
        <div>
          <h1 className="text-2xl font-bold">{survey.name}</h1>
          {survey.description && (
            <p className="text-gray-600 mt-2">{survey.description}</p>
          )}
        </div>
        <Button
          type="primary"
          onClick={() => handleOpenModal()}>
          질문 추가
        </Button>
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="questions">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="space-y-4"
            >
              {(survey.questions || []).map((question, index) => (
                <Draggable
                  key={question.id}
                  draggableId={String(question.id)}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Card
                        extra={
                          <Button
                            type="link"
                            onClick={() => handleOpenModal(question)}
                          >
                            수정
                          </Button>
                        }
                      >
                        <div>
                          <div className="font-medium">{question.question}</div>
                          <div className="text-gray-500 mt-1">
                            {question.type === QuestionType.SUBJECTIVE && '주관식'}
                            {question.type === QuestionType.SINGLE_CHOICE && '객관식 (단일)'}
                            {question.type === QuestionType.MULTIPLE_CHOICE && '객관식 (복수)'}
                            {question.required && ' (필수)'}
                          </div>
                          {(question.type === QuestionType.SINGLE_CHOICE ||
                            question.type === QuestionType.MULTIPLE_CHOICE) && (
                            <div className="mt-2 space-y-1">
                              {question.options.map((option) => (
                                <div
                                  key={option.id}
                                  className="text-gray-600"
                                >
                                  • {option.content}
                                  {option.isOther && ' (기타)'}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </Card>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Modal
        title={`질문 ${editingQuestion ? '수정' : '추가'}`}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={{ type: QuestionType.SUBJECTIVE }}
        >
          <Form.Item
            name="question"
            label="질문"
            rules={[{ required: true, message: '질문을 입력해주세요' }]}
          >
            <TextArea
              rows={3}
              placeholder="질문을 입력하세요" />
          </Form.Item>

          <Form.Item
            name="type"
            label="질문 유형"
            rules={[{ required: true, message: '질문 유형을 선택해주세요' }]}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value={QuestionType.SUBJECTIVE}>주관식</Radio>
                <Radio value={QuestionType.SINGLE_CHOICE}>객관식 (단일)</Radio>
                <Radio value={QuestionType.MULTIPLE_CHOICE}>객관식 (복수)</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) => prev.type !== curr.type}
          >
            {({ getFieldValue }) => {
              const type = getFieldValue('type')
              return (type === QuestionType.SINGLE_CHOICE ||
                      type === QuestionType.MULTIPLE_CHOICE) && (
                <Form.List name="options">
                  {(fields, { add, remove }) => (
                    <div className="space-y-2">
                      <div className="font-medium mb-2">선택지</div>
                      {fields.map((field) => (
                        <Space
                          key={field.key}
                          align="baseline">
                          <Form.Item
                            {...field}
                            name={[field.name, 'content']}
                            rules={[
                              {
                                required: true,
                                message: '선택지 내용을 입력해주세요'
                              }
                            ]}
                          >
                            <Input placeholder="선택지 내용" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'isOther']}
                            valuePropName="checked"
                          >
                            <Checkbox>기타</Checkbox>
                          </Form.Item>
                          {fields.length > 1 && (
                            <Button
                              type="text"
                              onClick={() => {
                                const values = form.getFieldValue('options')
                                if (values[field.name].isOther) {
                                  message.error('기타 옵션은 마지막에 위치해야 합니다')
                                  return
                                }
                                remove(field.name)
                              }}
                              danger
                            >
                              삭제
                            </Button>
                          )}
                        </Space>
                      ))}
                      <Button
                        type="dashed"
                        onClick={() => {
                          // const values = form.getFieldValue('options') || []
                          // const hasOther = values.some((opt: { isOther: boolean }) => opt.isOther)
                          add({ content: '', isOther: false })
                        }}
                        block
                      >
                        선택지 추가
                      </Button>
                    </div>
                  )}
                </Form.List>
              )
            }}
          </Form.Item>

          <div className="flex justify-end mt-4 space-x-2">
            <Button onClick={() => setIsModalOpen(false)}>취소</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={createQuestionMutation.isLoading || updateQuestionMutation.isLoading}
            >
              {editingQuestion ? '수정' : '추가'}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}