import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'

import { useSurveyStatistics } from '@/hooks/survey/useSurveyStatistics'

const SurveyStatisticsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data: statistics, isLoading } = useSurveyStatistics(Number(id))

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px">
        <CircularProgress />
      </Box>
    )
  }

  if (!statistics) {
    return (
      <Box p={3}>
        <Typography
          variant="h6"
          color="error">
          설문조사 통계를 불러올 수 없습니다.
        </Typography>
      </Box>
    )
  }

  return (
    <Box p={3}>
      <Typography
        variant="h4"
        gutterBottom>
        {statistics.name} 통계
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom>
        총 응답자 수: {statistics.totalResponses}명
      </Typography>

      <Box mt={4}>
        {statistics.questions.map((question) => (
          <Card
            key={question.id}
            sx={{ mb: 4 }}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom>
                {question.question}
              </Typography>

              <Box
                height={300}
                mt={2}>
                <ResponsiveContainer
                  width="100%"
                  height="100%">
                  <BarChart data={question.options}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="content" />
                    <YAxis />
                    <Tooltip />
                    <Bar
                      dataKey="count"
                      fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </Box>

              <Divider sx={{ my: 2 }} />

              <Typography
                variant="h6"
                gutterBottom>
                개별 응답 내역
              </Typography>
              <List>
                {question.answers.map((answer) => (
                  <ListItem key={answer.id}>
                    <ListItemText
                      primary={answer.user.name}
                      secondary={
                        answer.answer ||
                        question.options.find((opt) => opt.id === answer.optionId)?.content ||
                        '응답 없음'
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  )
}

export default SurveyStatisticsPage