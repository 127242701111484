import { useMutation } from 'react-query'

import { updateQuestion, UpdateQuestionRequestType } from '@/apis/survey/updateQuestion'

export default function useUpdateQuestion() {
  return useMutation<void, unknown, { id: number; data: UpdateQuestionRequestType }>(
    async ({ id, data }) => {
      await updateQuestion(id, data)
    }
  )
}