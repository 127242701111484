import { useMutation } from 'react-query'

import { createQuestion, CreateQuestionRequestType } from '@/apis/survey/createQuestion'

export default function useCreateQuestion() {
  return useMutation<void, unknown, CreateQuestionRequestType>(
    async (data) => {
      await createQuestion(data)
    }
  )
}