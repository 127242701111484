import { Button, Input, message, Modal, Space, Switch, Table } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useCreateSurvey from '@/hooks/survey/useCreateSurvey'
import useGetSurveys from '@/hooks/survey/useGetSurveys'
import useUpdateSurveyStatus from '@/hooks/survey/useUpdateSurveyStatus'
import { ROUTER } from '@/router'

export default function SurveyMainPage() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const navigate = useNavigate()

  const { data: surveys = [], refetch } = useGetSurveys()
  const createSurveyMutation = useCreateSurvey()
  const updateSurveyStatusMutation = useUpdateSurveyStatus()

  const columns = [
    {
      title: '제목',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '설명',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: string) => new Date(date).toLocaleDateString()
    },
    {
      title: '활성화',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: boolean, record: { id: number }) => (
        <div onClick={(e: React.MouseEvent) => e.stopPropagation()}>
          <Switch
            checked={isActive}
            onChange={(checked) => {
              updateSurveyStatusMutation.mutate(
                {
                  id: record.id,
                  data: { isActive: checked }
                },
                {
                  onSuccess: () => {
                    refetch()
                    message.success('상태가 변경되었습니다')
                  },
                  onError: (error) => {
                    message.error('상태 변경에 실패했습니다')
                    console.error('Status update error:', error)
                  }
                }
              )
            }}
            loading={updateSurveyStatusMutation.isLoading}
          />
        </div>
      )
    },
    {
      title: '작업',
      key: 'actions',
      render: (_: unknown, record: { id: number }) => (
        <div onClick={(e: React.MouseEvent) => e.stopPropagation()}>
          <Space>
            <Button
              type="link"
              onClick={() => navigate(`/survey/${record.id}`)}
            >
              상세
            </Button>
            <Button
              type="link"
              onClick={() => navigate(`/survey/${record.id}/users`)}
            >
              사용자 할당
            </Button>
          </Space>
        </div>
      )
    }
  ]

  const handleCreate = () => {
    if (!name) {
      message.error('제목을 입력해주세요')
      return
    }
    createSurveyMutation.mutate(
      { name, description },
      {
        onSuccess: () => {
          setIsModalOpen(false)
          setName('')
          setDescription('')
          refetch()
        }
      }
    )
  }

  return (
    <div className="p-6">
      <div className="flex justify-between mb-4">
        <h1 className="text-2xl font-bold">설문 관리</h1>
        <Button
          type="primary"
          onClick={() => setIsModalOpen(true)}>
          설문 생성
        </Button>
      </div>

      <Table
        dataSource={surveys}
        columns={columns}
        rowKey="id"
        onRow={(record) => ({
          onClick: (e) => {
            const target = e.target as HTMLElement
            const parentButton = target.closest('button')
            const parentSwitch = target.closest('.ant-switch')
            if (!parentButton && !parentSwitch) {
              navigate(`${ROUTER.SURVEY}/${record.id}`)
            }
          }
        })}
      />

      <Modal
        title="설문 생성"
        open={isModalOpen}
        onOk={handleCreate}
        onCancel={() => setIsModalOpen(false)}
        confirmLoading={createSurveyMutation.isLoading}
      >
        <div className="space-y-4">
          <div>
            <div className="mb-2">제목</div>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="설문 제목을 입력하세요"
            />
          </div>
          <div>
            <div className="mb-2">설명</div>
            <TextArea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="설문에 대한 설명을 입력하세요"
              rows={4}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}
